import * as React from "react";
import Redirect from "../../../components/redirect";
//--------------------------------------------------

  // this shared folder requires login
  const url = "https://yucolab-my.sharepoint.com/:f:/p/jimz_az/EsxR9QSzbHxNueoZU9MBencBUu8d52AqrlDouVMi-OTr0Q";

//--------------------------------------------------
// common config below this line, no need to change
const IndexPage = () => ( <Redirect goto_url={url} /> );
export default IndexPage;
